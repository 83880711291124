import React from 'react'
import { Link } from 'gatsby'
import { FaGithub, FaCode } from 'react-icons/fa'
import { FaBluesky } from "react-icons/fa6";

import { rhythm, scale } from '../utils/typography'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {header}
        {children}
        <footer
          style={{
            display: `flex`,
            flexDirection: `row`,
            alignItems: `center`,
            justifyContent: `space-between`,
            boxShadow: `none`,
          }}
        >
          <div>
            © {new Date().getFullYear()}, Built with
          {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </div>
          <div
          >
            <Link 
              to='/konami'
              aria-label='Konami Codes'
              style={{
                boxShadow: `none`,
              }}
            >
              <FaCode />
            </Link> 
            <a 
              aria-label='Github'
              style={{
                boxShadow: `none`,
                marginLeft: `3px`,
              }}
              href='https://github.com/infomofo'><FaGithub /></a>
            <a 
              aria-label='Bluesky'
              style={{
                boxShadow: `none`,
                marginLeft: `3px`,
              }}
              href='https://bsky.app/profile/infomofo.com'><FaBluesky /></a>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
